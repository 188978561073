import React, { useEffect } from 'react'
import SEO from '../components/SEO'
import Header from '../components/header'
// import { loadScriptSrcAfterBody } from '../utils'
import Footer from '../components/footer'
export default function () {
  const onClickDownload = (key) => {
    var typeToLink = {
      android: 'https://dl.turbo.zenlayer.net/turbozen_ie/android/latest',
      iphone: 'https://dl.turbo.zenlayer.net/turbozen_ie/ios/latest',
      windows: 'https://dl.turbo.zenlayer.net/turbozen_ie/windows/latest',
      macos: 'https://dl.turbo.zenlayer.net/turbozen_ie/macos/latest',
      dashboard: 'https://portal.turbo.zenlayer.net/auth/login',
      icon: '/turbo-icons/',
    }
    var link = typeToLink[key]
    if (link) {
      window.open(link)
    }
  }
  return (
    <div className="turbo">
      <div>
        <SEO title="Turbo | Zenlayer" description="" viewport="width = 1440">
          <script src="/turbo-assets/flexible.js"></script>
          <link
            rel="stylesheet"
            type="text/css"
            href="/turbo-assets/common.css"
          />
          <link
            rel="stylesheet"
            type="text/css"
            href="/turbo-assets/index.css"
          />{' '}
          <link
            href="https://fonts.googleapis.com/css?family=Open+Sans:400italic,600italic,700italic,400,600,700"
            rel="stylesheet"
            type="text/css"
          />
        </SEO>
        <div className="bg bg1"></div>
        <div className="bg bg2"></div>
        <div className="bg bg3"></div>
        <div className="bg bg4"></div>
        <div className="bg bg5"></div>
        <Header theme="dark" />
        <div className="page flex-col">
          <div className="box_1 flex-col">
            <img className="top-pic" src="/turbo-assets/img/top-pic.png"></img>
            <div className="group_33 flex-col">
              {/* <img
                className="image_21"
                referrerPolicy="no-referrer"
                src="/turbo-assets/img/SketchPnge1e2688ff0ce849329f4d951284da9095a03bc9cd9eb055f1b91e6b3d1794e73.png"
              /> */}
              <img
                className="image_22"
                referrerPolicy="no-referrer"
                src="/turbo-assets/img/SketchPng0dd19cb55292f3bdc93726b985b5d57d88591cff32ac6f3c3bc9fd4d49bcc649.png"
              />
              <span className="text_1">Speed up 350+ SaaS apps</span>
              <span className="paragraph_1">
                Acceleration that’s instant, secure, and in your control.
                <br />
                <br />
              </span>
              <div className="group_34 flex-row justify-between">
                <div
                  className="box_10 flex-row link-btn"
                  onClick={() => {
                    onClickDownload('iphone')
                  }}
                >
                  <div className="image-text_50 flex-row justify-between">
                    <span className="iconfont" style={{ bottom: '4px' }}>
                      &#xe882;{' '}
                    </span>

                    <span className="text-group_3">iPhone</span>
                  </div>
                  <div className="image-wrapper_30 flex-col">
                    <span className="iconfont">&#xe7d9;</span>
                  </div>
                </div>
                <div
                  className="block_4 flex-row justify-between link-btn"
                  onClick={() => {
                    onClickDownload('android')
                  }}
                >
                  <div className="image-text_51 flex-row justify-between">
                    <div className="section_19 flex-col">
                      <div className="image-wrapper_27 flex-col">
                        <span className="iconfont" style={{ bottom: '6px' }}>
                          &#xe881;{' '}
                        </span>
                      </div>
                    </div>
                    <span className="text-group_1">Android</span>
                  </div>
                  <div className="image-wrapper_28 flex-col">
                    <span className="iconfont">&#xe7d9;</span>
                  </div>
                </div>
              </div>
              <div className="group_35 flex-row justify-between">
                <div
                  className="box_11 flex-row link-btn"
                  onClick={() => {
                    onClickDownload('macos')
                  }}
                >
                  <div className="image-text_52 flex-row justify-between">
                    <span className="iconfont" style={{ bottom: '4px' }}>
                      &#xe882;{' '}
                    </span>
                    <span className="text-group_4">macOS</span>
                  </div>
                  <div className="image-wrapper_31 flex-col">
                    <span className="iconfont">&#xe7d9;</span>
                  </div>
                </div>
                <div
                  className="block_4 flex-row justify-between link-btn"
                  onClick={() => {
                    onClickDownload('windows')
                  }}
                >
                  <div className="image-text_51 flex-row justify-between">
                    <div className="section_19 flex-col">
                      <div className="image-wrapper_27 flex-col">
                        <span className="iconfont" style={{ bottom: '6px' }}>
                          &#xe883;{' '}
                        </span>
                      </div>
                    </div>
                    <span className="text-group_1">Windows</span>
                  </div>
                  <div className="image-wrapper_28 flex-col">
                    <span className="iconfont">&#xe7d9;</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="box_14 flex-col">
            <span className="paragraph_2">
              Turbo accelerates the apps your business depends on
              <br />
            </span>
            <div className="grid_5 flex-row">
              <div className="section_30 flex-col">
                <span className="text_93">Video conferencing</span>
                <img
                  className="image_96"
                  referrerPolicy="no-referrer"
                  src="/turbo-assets/img/SketchPnge3d9a1ea50d79a60388326b05fc3df8e70bba6d964e1b313aee07c0cddabf69c.png"
                />
                <div className="group_36 flex-row justify-between">
                  <div className="image-text_54 flex-col justify-between">
                    <div className="image-wrapper_32 flex-col">
                      <img
                        className="image_67"
                        referrerPolicy="no-referrer"
                        src="/turbo-assets/img/SketchPng344f2b77b85370bc6d7a563ec662cda9299fa8e154f70284ca0a473b7705903d.png"
                      />
                    </div>
                    <span className="text-group_5">Zoom</span>
                  </div>
                  <div className="image-text_55 flex-col justify-between">
                    <div className="image-wrapper_33 flex-col">
                      <img
                        className="image_97"
                        referrerPolicy="no-referrer"
                        src="/turbo-assets/img/SketchPng2205767a7ce9428b2e814f565ec0954c2e33a7b6fe9f93139eeb4fdeea3a8980.png"
                      />
                    </div>
                    <span className="text_3">Teams</span>
                  </div>
                </div>
              </div>
              <div className="section_31 flex-col">
                <span className="text_94">Productivity</span>
                <img
                  className="image_98"
                  referrerPolicy="no-referrer"
                  src="/turbo-assets/img/SketchPnge3d9a1ea50d79a60388326b05fc3df8e70bba6d964e1b313aee07c0cddabf69c.png"
                />
                <div className="image-wrapper_47 flex-row justify-between">
                  <img
                    className="image_70"
                    referrerPolicy="no-referrer"
                    src="/turbo-assets/img/SketchPng20bbfb8e58e597774a1c93773f77ddbab3c556916fdcdbcc80d49311f4687c47.png"
                  />
                  <img
                    className="image_71"
                    referrerPolicy="no-referrer"
                    src="/turbo-assets/img/SketchPngad500aa434fb0f81364647ea13886bd990833c35a52d0003fcf99728570a181f.png"
                  />
                </div>
                <div className="text-wrapper_29 flex-row justify-between">
                  <span className="text_85">Office365</span>
                  <span className="text_86">Atlassian</span>
                </div>
                <div className="block_19 flex-col"></div>
                <div className="block_20 flex-col"></div>
              </div>
              <div className="section_32 flex-col">
                <span className="text_95">Sales</span>
                <img
                  className="image_99"
                  referrerPolicy="no-referrer"
                  src="/turbo-assets/img/SketchPnge3d9a1ea50d79a60388326b05fc3df8e70bba6d964e1b313aee07c0cddabf69c.png"
                />
                <div className="box_45 flex-row justify-between">
                  <div className="box_46 flex-col justify-between">
                    <img
                      className="image_73"
                      referrerPolicy="no-referrer"
                      src="/turbo-assets/img/SketchPngad28671c9916e8e55a2c86bed6fbcc31e01c9c2c23369b27b78f9b8363627a51.png"
                    />
                    <span className="text_88">Salesforce</span>
                  </div>
                  <div className="image-text_56 flex-col justify-between">
                    <div className="block_21 flex-col">
                      <div className="group_28 flex-col"></div>
                      <img
                        className="image_74"
                        referrerPolicy="no-referrer"
                        src="/turbo-assets/img/SketchPng802974bf0d47ae461c1804f7ab11407ec4b18934ec0d9f08e4b5baeb396689b3.png"
                      />
                    </div>
                    <span className="text-group_21">HubSpot</span>
                  </div>
                </div>
                <div className="section_24 flex-col"></div>
              </div>
              <div className="section_33 flex-col">
                <span className="text_96">Developer</span>
                <img
                  className="image_100"
                  referrerPolicy="no-referrer"
                  src="/turbo-assets/img/SketchPnge3d9a1ea50d79a60388326b05fc3df8e70bba6d964e1b313aee07c0cddabf69c.png"
                />
                <div className="box_47 flex-row justify-between">
                  <div className="image-text_57 flex-col justify-between">
                    <div className="image-wrapper_35 flex-col">
                      <img
                        className="image_76"
                        referrerPolicy="no-referrer"
                        src="/turbo-assets/img/SketchPng70980b1306e4ad8151faae089cf3bd3f9eb3e210df967807591f787edbd2106e.png"
                      />
                    </div>
                    <span className="text-group_22">VMware</span>
                  </div>
                  <div className="image-text_58 flex-col justify-between">
                    <div className="image-wrapper_36 flex-col">
                      <img
                        className="image_77"
                        referrerPolicy="no-referrer"
                        src="/turbo-assets/img/SketchPng6aed7921f46f47c84c70baff39f4b21a1a190460be6d88fa50f695526282df45.png"
                      />
                    </div>
                    <span className="text-group_23">GitHub</span>
                  </div>
                </div>
              </div>
              <div className="section_34 flex-col">
                <span className="text_97">Finance</span>
                <img
                  className="image_101"
                  referrerPolicy="no-referrer"
                  src="/turbo-assets/img/SketchPnge3d9a1ea50d79a60388326b05fc3df8e70bba6d964e1b313aee07c0cddabf69c.png"
                />
                <div className="section_35 flex-row justify-between">
                  <div className="image-text_59 flex-col justify-between">
                    <div className="image-wrapper_37 flex-col">
                      <img
                        className="image_79"
                        referrerPolicy="no-referrer"
                        src="/turbo-assets/img/SketchPngdc8f3c7a85c94ed924cb8bc1ed7575a83bd1d93263f640466636842baba8c603.png"
                      />
                    </div>
                    <span className="text-group_24">NetSuite</span>
                  </div>
                  <div className="image-text_60 flex-col justify-between">
                    <div className="image-wrapper_38 flex-col">
                      <img
                        className="image_80"
                        referrerPolicy="no-referrer"
                        src="/turbo-assets/img/SketchPngab69c91ca060f73fb2fcef00b27b22f95a99f1627792aa9128c91e5c28f44876.png"
                      />
                    </div>
                    <span className="text-group_25">Sap</span>
                  </div>
                </div>
              </div>
              <div className="section_36 flex-col">
                <span className="text_98">and more...</span>
                <img
                  className="image_102"
                  referrerPolicy="no-referrer"
                  src="/turbo-assets/img/SketchPnge3d9a1ea50d79a60388326b05fc3df8e70bba6d964e1b313aee07c0cddabf69c.png"
                />
                <div className="box_48 flex-row justify-between">
                  <div className="box_49 flex-col justify-between">
                    <img
                      className="image_82"
                      referrerPolicy="no-referrer"
                      src="/turbo-assets/img/SketchPng8c0ca50c0be4d5fb44f24fbb8fe23e14af9311bf592e2ba002d094111510987e.png"
                    />
                    <span className="text_92">Slack</span>
                  </div>
                  <div className="image-text_61 flex-col justify-between">
                    <div className="image-wrapper_39 flex-col">
                      <img
                        className="image_103"
                        referrerPolicy="no-referrer"
                        src="/turbo-assets/img/SketchPnge790daeac6a08010b1403109d7007625608e160bfe23d588eccb7a81c8a1fb7d.png"
                      />
                    </div>
                    <span className="text-group_26">Adobe</span>
                  </div>
                </div>
                <div className="section_26 flex-col"></div>
              </div>
            </div>
            <div
              className="text-wrapper_2 flex-col link"
              onClick={() => {
                onClickDownload('icon')
              }}
            >
              <span className="text_4">
                See all 350+ apps that Turbo accelerates!
              </span>
            </div>
          </div>
          <div className="box_50 flex-col">
            <div className="block_6 flex-col"></div>
            <div className="block_7 flex-col">
              <div className="block_8 flex-col"></div>
            </div>
            <div className="block_9 flex-col">
              <div className="image-wrapper_10 flex-col">
                <div className="image_29 flex-col">
                  <div className="group_37 flex-row">
                    <div className="block_10 flex-row">
                      <div className="text-group_42 flex-col">
                        <span className="text_8">Reduce lag to</span>
                        <span className="text_9">350+</span>
                        <span className="text_10">top SaaS apps</span>
                      </div>
                    </div>
                    <div className="block_11 flex-row">
                      <div className="text-group_43 flex-col">
                        <span className="text_11">Boost speed by</span>
                        <span className="text_12">50-500%</span>
                        <span className="text_13">in real-time</span>
                      </div>
                    </div>
                    <div className="box_16 flex-row">
                      <div className="text-group_44 flex-col">
                        <span className="text_5">Access from</span>
                        <span className="text_6">200+</span>
                        <span className="text_7">cities worldwide</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="box_17 flex-col">
            <div className="text-group_45 flex-col justify-between">
              <span className="text_14">Flexible pricing plans</span>
              <span className="text_15">
                Whether you want to speed up your home network to improve
                remote-work productivity or bring down latency for more
                efficient collaboration across teams, Turbo is your ideal
                solution.
              </span>
            </div>
            <div className="box_51 flex-row justify-between">
              <div className="group_38 flex-col justify-between">
                <div className="text-wrapper_3 flex-col">
                  <span className="text_16">Instant acceleration</span>
                </div>
                <div className="text-wrapper_4 flex-col">
                  <span className="text_17">End-to-end encryption</span>
                </div>
                <div className="text-wrapper_5 flex-col">
                  <span className="text_18">Easy setup and operation</span>
                </div>
                <div className="text-wrapper_6 flex-col">
                  <span className="text_19">
                    Improved cross-regional collaboration efficiency
                  </span>
                </div>
                <div className="text-wrapper_7 flex-col">
                  <span className="text_20">
                    Customizable connections and plans
                  </span>
                </div>
                <div className="text-wrapper_8 flex-col">
                  <span className="text_21">
                    Access to all available acceleration locations
                  </span>
                </div>
                <div className="text-wrapper_9 flex-col">
                  <span className="text_22">
                    Convenient dashboard for monitoring and control
                  </span>
                </div>
                <div className="text-wrapper_10 flex-col">
                  <span className="text_23">Multi-device support</span>
                </div>
              </div>
              <div className="group_39 flex-col justify-between">
                <div className="box_52 flex-row justify-between">
                  <div className="box_18 flex-row">
                    <div className="text-group_46 flex-col justify-between">
                      <span className="text_24">Turbo</span>
                      <span className="text_25">Free for personal use</span>
                    </div>
                  </div>
                  <div className="box_19 flex-row">
                    <div className="text-group_47 flex-col justify-between">
                      <span className="text_26">Turbo Enterprise</span>
                      <span className="text_27">Contact us for pricing</span>
                    </div>
                  </div>
                </div>
                <div className="block_13 flex-col">
                  <div className="image-wrapper_11 flex-col">
                    <img
                      className="image_30"
                      referrerPolicy="no-referrer"
                      src="/turbo-assets/img/SketchPng7f090b3caef8bcbc5889b0e15ad7d0df627df1f285c8b0cd5c45dc8ee5f8008a.png"
                    />
                  </div>
                  <div className="image-wrapper_12 flex-col">
                    <img
                      className="image_31"
                      referrerPolicy="no-referrer"
                      src="/turbo-assets/img/SketchPng7f090b3caef8bcbc5889b0e15ad7d0df627df1f285c8b0cd5c45dc8ee5f8008a.png"
                    />
                  </div>
                  <div className="image-wrapper_13 flex-col">
                    <img
                      className="image_32"
                      referrerPolicy="no-referrer"
                      src="/turbo-assets/img/SketchPng9c02dd1b09b602e25b3977159876a6d9dcb57b68680ffab609ea273285e80288.png"
                    />
                  </div>
                  <div className="image-wrapper_14 flex-col">
                    <img
                      className="image_33"
                      referrerPolicy="no-referrer"
                      src="/turbo-assets/img/SketchPng87a09728c3698f8a9158755ee143f4d23ab2fe14af0bc5200391774b3f4ba267.png"
                    />
                  </div>
                  <div className="image-wrapper_15 flex-col">
                    <img
                      className="image_34"
                      referrerPolicy="no-referrer"
                      src="/turbo-assets/img/SketchPng87a09728c3698f8a9158755ee143f4d23ab2fe14af0bc5200391774b3f4ba267.png"
                    />
                  </div>
                  <div className="image-wrapper_16 flex-col">
                    <img
                      className="image_35"
                      referrerPolicy="no-referrer"
                      src="/turbo-assets/img/SketchPng87a09728c3698f8a9158755ee143f4d23ab2fe14af0bc5200391774b3f4ba267.png"
                    />
                  </div>
                  <div className="image-wrapper_17 flex-col">
                    <img
                      className="image_36"
                      referrerPolicy="no-referrer"
                      src="/turbo-assets/img/SketchPng87a09728c3698f8a9158755ee143f4d23ab2fe14af0bc5200391774b3f4ba267.png"
                    />
                  </div>
                  <div className="image-wrapper_18 flex-col">
                    <img
                      className="image_37"
                      referrerPolicy="no-referrer"
                      src="/turbo-assets/img/SketchPng87a09728c3698f8a9158755ee143f4d23ab2fe14af0bc5200391774b3f4ba267.png"
                    />
                  </div>
                  <div className="box_20 flex-col">
                    <div className="image-wrapper_19 flex-col">
                      <img
                        className="image_38"
                        referrerPolicy="no-referrer"
                        src="/turbo-assets/img/SketchPng7f090b3caef8bcbc5889b0e15ad7d0df627df1f285c8b0cd5c45dc8ee5f8008a.png"
                      />
                    </div>
                    <div className="image-wrapper_20 flex-col">
                      <img
                        className="image_39"
                        referrerPolicy="no-referrer"
                        src="/turbo-assets/img/SketchPng7f090b3caef8bcbc5889b0e15ad7d0df627df1f285c8b0cd5c45dc8ee5f8008a.png"
                      />
                    </div>
                    <div className="image-wrapper_21 flex-col">
                      <img
                        className="image_40"
                        referrerPolicy="no-referrer"
                        src="/turbo-assets/img/SketchPng9c02dd1b09b602e25b3977159876a6d9dcb57b68680ffab609ea273285e80288.png"
                      />
                    </div>
                    <div className="image-wrapper_22 flex-col">
                      <img
                        className="image_41"
                        referrerPolicy="no-referrer"
                        src="/turbo-assets/img/SketchPng922cb24697f0a6df38e338ce766a2d130d29ac021a2984147f3eb2aec9690565.png"
                      />
                    </div>
                    <img
                      className="image_42"
                      referrerPolicy="no-referrer"
                      src="/turbo-assets/img/SketchPng8bd45f12c88aeee3e08c9f05cc99cbc8fb697ac0d8553c6b9b6638578c2fa64a.png"
                    />
                    <img
                      className="image_43"
                      referrerPolicy="no-referrer"
                      src="/turbo-assets/img/SketchPng8bd45f12c88aeee3e08c9f05cc99cbc8fb697ac0d8553c6b9b6638578c2fa64a.png"
                    />
                    <img
                      className="image_44"
                      referrerPolicy="no-referrer"
                      src="/turbo-assets/img/SketchPng8bd45f12c88aeee3e08c9f05cc99cbc8fb697ac0d8553c6b9b6638578c2fa64a.png"
                    />
                    <img
                      className="image_45"
                      referrerPolicy="no-referrer"
                      src="/turbo-assets/img/SketchPng8bd45f12c88aeee3e08c9f05cc99cbc8fb697ac0d8553c6b9b6638578c2fa64a.png"
                    />
                    <img
                      className="image_46"
                      referrerPolicy="no-referrer"
                      src="/turbo-assets/img/SketchPng8bd45f12c88aeee3e08c9f05cc99cbc8fb697ac0d8553c6b9b6638578c2fa64a.png"
                    />
                    <img
                      className="image_47"
                      referrerPolicy="no-referrer"
                      src="/turbo-assets/img/SketchPng8bd45f12c88aeee3e08c9f05cc99cbc8fb697ac0d8553c6b9b6638578c2fa64a.png"
                    />
                    <img
                      className="image_48"
                      referrerPolicy="no-referrer"
                      src="/turbo-assets/img/SketchPng8bd45f12c88aeee3e08c9f05cc99cbc8fb697ac0d8553c6b9b6638578c2fa64a.png"
                    />
                  </div>
                </div>
              </div>
            </div>
            <img
              className="image_49"
              referrerPolicy="no-referrer"
              src="/turbo-assets/img/SketchPng8bd45f12c88aeee3e08c9f05cc99cbc8fb697ac0d8553c6b9b6638578c2fa64a.png"
            />
            <div className="section_9 flex-col">
              <span className="text_28">Try Turbo Enterprise now!</span>
              <div className="image-text_62 flex-row justify-between">
                <div className="text-wrapper_22 flex-col">
                  <span className="text_30">2</span>
                </div>
                <div className="text-group_48 flex-col justify-between">
                  <span className="paragraph_3">
                    Purchase enterprise licenses by contacting
                    <br />
                    product-turbo&#64;zenlayer.com
                  </span>
                  <span className="text_31">
                    Customize routing based on your business needs
                  </span>
                </div>
              </div>
              <div className="image-text_63 flex-row justify-between">
                <div className="text-wrapper_23 flex-col">
                  <span className="text_32">3</span>
                </div>
                <span className="text_33">
                  Guide employees to install the Turbo client and start enjoying
                  faster speeds
                </span>
              </div>
              <div className="image-text_64 flex-row justify-between">
                <div className="text-wrapper_24 flex-col">
                  <span className="text_29">1</span>
                </div>
                <div className="text-group_49 flex-col justify-between">
                  <span className="paragraph_3">
                    Purchase enterprise licenses by contacting
                    <br />
                    product-turbo&#64;zenlayer.com
                  </span>
                  <span className="text_31">
                    Customize routing based on your business needs
                  </span>
                </div>
              </div>
            </div>
            <div className="section_10 flex-col">
              <span className="text_34">Try Turbo now!</span>
              <div className="image-text_65 flex-row justify-between">
                <div className="text-wrapper_25 flex-col">
                  <span className="text_36">2</span>
                </div>
                <div className="text-group_50 flex-col justify-between">
                  <span className="text_37">
                    Download and install the Turbo client based on your
                    operating system
                  </span>
                  <span className="text_38">
                    Register for an account and log in to start accelerating
                    your traffic
                  </span>
                </div>
              </div>
              <div className="image-text_66 flex-row justify-between">
                <div className="text-wrapper_26 flex-col">
                  <span className="text_35">1</span>
                </div>
                <div className="text-group_51 flex-col justify-between">
                  <span className="text_37">
                    Download and install the Turbo client based on your
                    operating system
                  </span>
                  <span className="text_38">
                    Register for an account and log in to start accelerating
                    your traffic
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="box_21 flex-col">
            <span className="text_39">Frequently Asked Questions</span>
            <span className="text_40">What is Turbo?</span>
            <span className="text_41">
              Turbo is an acceleration application that speeds up your
              connections to 350+ top SaaS applications, private and public
              clouds, and remote team members.
            </span>
            <div className="text-group_52 flex-col justify-between">
              <span className="text_42">
                How does Turbo speed up my network, and is it secure?
              </span>
              <span className="text_43">
                Turbo uses a combination of intelligent traffic engineering and
                Zenlayer’s massive global private backbone to accelerate and
                safeguard your connections from end to end.
              </span>
            </div>
            <span className="text_44">
              How does Turbo improve productivity?
            </span>
            <span className="text_45">
              Turbo minimizes the latency of your apps and communications to
              eliminate lags, timeouts, and long load times so that you and your
              team can spend more time getting things done and waste less time
              dealing with network disruptions and connection errors.
            </span>
            <span className="text_46">
              What are some examples of apps Turbo is compatible with?
            </span>
            <span className="text_47">
              Turbo boosts the speed of your mission-critical apps like
              SalesForce, Office 365, Zoom, YouTube, NetSuite, Teams, and more.
              If you want us to accelerate an app that isn’t currently supported
              by Turbo, be sure to let us know!
            </span>
            <span className="text_48">
              How many devices can one Turbo subscription support?
            </span>
            <span className="paragraph_4">
              Turbo can be used on one device with a free subscription. Turbo
              Enterprise subscription is required for use on multiple devices.
              Please contact our solution experts by emailing
              <br />
              product-turbo&#64;zenlayer.com for enterprise license sales and
              pricing.
            </span>
            <span className="text_49">
              What level of customer support can I expect?
            </span>
            <span className="text_50">
              Like with other Zenlayer products and services, you can rest easy
              with our 24/7, 365-day tech support team. Just email us at
              product-turbo&#64;zenlayer.com if you have any questions or need
              assistance!
            </span>
          </div>
          <div className="box_22 flex-col">
            <span className="text_51">
              Decrease latency and increase productivity. Instantly.
            </span>
            <div className="grid_6 flex-row">
              <div className="list-items_2-0 flex-row">
                <div className="box_23-0 flex-col">
                  <div className="block_15-0 flex-col"></div>
                </div>
                <div className="text-wrapper_30-0 flex-col justify-between">
                  <span className="text_54-0">Collaborate seamlessly</span>
                  <span className="text_55-0">
                    Accelerate real-time interactions for enterprise
                    applications and collaborate around the globe with ultra-low
                    latency. Eliminate long load times and connection timeouts,
                    and work together like your team is in the same room.
                  </span>
                </div>
              </div>
              <div className="list-items_2-1 flex-row">
                <div className="box_23-1 flex-col">
                  <div className="block_15-1 flex-col"></div>
                </div>
                <div className="text-wrapper_30-1 flex-col justify-between">
                  <span className="text_54-1">Meet productively</span>
                  <span className="text_55-1">
                    Eliminate distractions from lags, dropped frames, and other
                    common connectivity issues that disrupt virtual meetings.
                    Delegate tasks and reach milestones faster with smoother
                    digital communications.
                  </span>
                </div>
              </div>
              <div className="list-items_2-2 flex-row">
                <div className="box_23-2 flex-col">
                  <div className="block_15-2 flex-col"></div>
                </div>
                <div className="text-wrapper_30-2 flex-col justify-between">
                  <span className="text_54-2">Access clouds quicker</span>
                  <span className="text_55-2">
                    Get blazing fast, secure connections and sync up with your
                    public and private clouds anytime, anywhere.
                  </span>
                </div>
              </div>
              <div className="list-items_2-3 flex-row">
                <div className="box_23-3 flex-col">
                  <div className="block_15-3 flex-col"></div>
                </div>
                <div className="text-group_53-3 flex-col justify-between">
                  <span className="text_52-3">Get round-the-clock support</span>
                  <span className="text_53-3">
                    Boost the remote responsiveness and efficiency of your
                    services. Our support team is available wherever and
                    whenever you need help to make your services shine.
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="box_24 flex-col">
            <div className="section_37 flex-row justify-between">
              <div className="image-text_67 flex-row justify-between">
                <img
                  className="thumbnail_9"
                  referrerPolicy="no-referrer"
                  src="/turbo-assets/img/SketchPngd9ad12bf791e2a6445bb27de946f4814fca4ded477ffd5bdad0272947e78bdbc.png"
                />
                <span className="paragraph_6">
                  Defend against DDoS attacks
                  <br />
                  <br />
                </span>
              </div>
              <span className="text_66">
                Sit back and relax with 24/7, 365-day tech support
              </span>
            </div>
            <span className="text_67">
              1 Our free plan includes select locations, upgrade to Turbo
              Enterprise for full selection. <br />2 Requires Turbo Enterprise.
            </span>
            <div className="section_38 flex-row">
              <div className="text-wrapper_31 flex-col">
                <span className="text_56">
                  Connect from anywhere, to everywhere{' '}
                  <span className="tiny-font">1</span>
                </span>
                <span className="paragraph_5">
                  Get WOW service + ongoing support
                  <br />
                  <br />
                </span>
                <span className="text_63">
                  Manage licenses and usage with ease through an intuitive
                  dashboard <span className="tiny-font">2</span>
                </span>
              </div>
              <div className="box_53 flex-row">
                <img
                  className="image_104"
                  referrerPolicy="no-referrer"
                  src="/turbo-assets/img/SketchPng9f7a427c30e4bf7f70bba130e94bf5ab68bbf84955fe65f1824b8f1ef0feb45c.png"
                />
                <img
                  className="image_105"
                  referrerPolicy="no-referrer"
                  src="/turbo-assets/img/SketchPngf6091906f5299a5489013997dfea10e550facc847f8409d74f50bebadd33ef8f.png"
                />
                <div className="image-text_68 flex-row justify-between">
                  <img
                    className="thumbnail_10"
                    referrerPolicy="no-referrer"
                    src="/turbo-assets/img/SketchPng251ae2c689c985a6ef06e6fb5b9ac3972fd84806beb54f9e4069648e82b1630e.png"
                  />
                  <span className="text_58">Speeds up 350+ apps</span>
                </div>
                <div className="image-text_69 flex-row justify-between">
                  <div className="image-wrapper_40 flex-col">
                    <img
                      className="thumbnail_11"
                      referrerPolicy="no-referrer"
                      src="/turbo-assets/img/SketchPngfdd8515f2ffd9426234d48f9dec7f5079b00a875545b49e3916c684ee4a3e3d4.png"
                    />
                  </div>
                  <span className="text_59">No lag or frame drops</span>
                </div>
                <div className="image-text_70 flex-row justify-between">
                  <div className="image-wrapper_41 flex-col">
                    <img
                      className="thumbnail_12"
                      referrerPolicy="no-referrer"
                      src="/turbo-assets/img/SketchPngfdd8515f2ffd9426234d48f9dec7f5079b00a875545b49e3916c684ee4a3e3d4.png"
                    />
                  </div>
                  <span className="text_60">Stable network performance</span>
                </div>
                <div className="image-text_71 flex-row justify-between">
                  <div className="image-wrapper_42 flex-col">
                    <img
                      className="thumbnail_13"
                      referrerPolicy="no-referrer"
                      src="/turbo-assets/img/SketchPngfdd8515f2ffd9426234d48f9dec7f5079b00a875545b49e3916c684ee4a3e3d4.png"
                    />
                  </div>
                  <span className="text_61">Easy to use</span>
                </div>
                <div className="image-text_72 flex-row justify-between">
                  <img
                    className="thumbnail_14"
                    referrerPolicy="no-referrer"
                    src="/turbo-assets/img/SketchPngd9ad12bf791e2a6445bb27de946f4814fca4ded477ffd5bdad0272947e78bdbc.png"
                  />
                  <span className="text_64">
                    Keep user identity secure with TLS encryption
                  </span>
                </div>
                <div className="image-text_73 flex-row justify-between">
                  <img
                    className="thumbnail_15"
                    referrerPolicy="no-referrer"
                    src="/turbo-assets/img/SketchPngd9ad12bf791e2a6445bb27de946f4814fca4ded477ffd5bdad0272947e78bdbc.png"
                  />
                  <span className="text_65">
                    Lock down data privacy with AES-256 encryption
                  </span>
                </div>
                <div className="section_39 flex-col">
                  <div className="group_19 flex-col">
                    <div className="block_27 flex-row">
                      <div className="text-wrapper_17">
                        <span className="text_68">Why choose Turbo</span>
                        <span className="text_69">？</span>
                        <span className="paragraph_7">
                          <br />
                        </span>
                      </div>
                    </div>
                    <div className="block_28 flex-row justify-between">
                      <div className="group_20 flex-col">
                        <div className="box_54 flex-col">
                          <div className="block_16 flex-col">
                            <div className="image-wrapper_23 flex-col">
                              <img
                                className="image_54"
                                referrerPolicy="no-referrer"
                                src="/turbo-assets/img/SketchPngca3c043e920c394b52fe6ecbdc6556c42159b74ca02c3cf778734c1de3d647cd.png"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="group_40 flex-col">
                        <img
                          className="image_106"
                          referrerPolicy="no-referrer"
                          src="/turbo-assets/img/SketchPng945030e4034fd6e59dee35e5e8aea07a462958ae0f65066ac2bc005402257163.png"
                        />
                        <span className="text_70"></span>
                        <div className="image-text_74 flex-row justify-between">
                          <img
                            className="thumbnail_16"
                            referrerPolicy="no-referrer"
                            src="/turbo-assets/img/SketchPng97c6907ecafe22ab78d99cdc8799d2b59e939e885831964c3833f90c46c6c30c.png"
                          />
                          <div className="text-group_54 flex-col">
                            <span className="text_71">6 continents</span>
                            <span className="text_72">40+ countries</span>
                            <span className="text_73">200+ key cities</span>
                          </div>
                        </div>
                        <img
                          className="image_107"
                          referrerPolicy="no-referrer"
                          src="/turbo-assets/img/SketchPng55d27693f82df9a590a23c6e1fdf622abf9083ce597f3adc381f6a7650c2d65e.png"
                        />
                        <img
                          className="thumbnail_17"
                          referrerPolicy="no-referrer"
                          src="/turbo-assets/img/SketchPngd9ad12bf791e2a6445bb27de946f4814fca4ded477ffd5bdad0272947e78bdbc.png"
                        />
                        <span className="text_74"></span>
                        <img
                          className="image_58"
                          referrerPolicy="no-referrer"
                          src="/turbo-assets/img/SketchPng97c6907ecafe22ab78d99cdc8799d2b59e939e885831964c3833f90c46c6c30c.png"
                        />
                        <div className="image-text_75 flex-row justify-between">
                          <img
                            className="thumbnail_18"
                            referrerPolicy="no-referrer"
                            src="/turbo-assets/img/SketchPng251ae2c689c985a6ef06e6fb5b9ac3972fd84806beb54f9e4069648e82b1630e.png"
                          />
                          <div className="text-group_55 flex-col">
                            <span className="text_71">6 continents</span>
                            <span className="text_72">40+ countries</span>
                            <span className="text_73">200+ key cities</span>
                          </div>
                        </div>
                        <div className="image-text_76 flex-row justify-between">
                          <img
                            className="thumbnail_19"
                            referrerPolicy="no-referrer"
                            src="/turbo-assets/img/SketchPng97c6907ecafe22ab78d99cdc8799d2b59e939e885831964c3833f90c46c6c30c.png"
                          />
                          <div className="text-group_56 flex-col">
                            <span className="text_71">6 continents</span>
                            <span className="text_72">40+ countries</span>
                            <span className="text_73">200+ key cities</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <span className="text_57">
                  Instantly accelerate apps with a single click
                </span>
                <span className="text_62">Protect all that matters</span>
              </div>
            </div>
          </div>
          <div className="box_25 flex-col">
            <span className="text_75">
              Turbo is compatible with the most popular operating systems on PCs
              and smartphones
            </span>
            <div className="group_41 flex-row justify-between">
              <div
                className="group_22 flex-col link"
                onClick={() => {
                  onClickDownload('iphone')
                }}
              >
                <div className="image-text_77 flex-col justify-between">
                  <img
                    className="image_88"
                    referrerPolicy="no-referrer"
                    src="/turbo-assets/img/SketchPng14430e98a46e69f0625619ba2007bb968f988da0e715c8f9c3455093c923f58d.png"
                  />
                  <span className="text_76">iPhone</span>
                </div>
                <img
                  className="label_3"
                  referrerPolicy="no-referrer"
                  src="/turbo-assets/img/SketchPng87dc9eb497a1d594fbf64d13cb1f1c7ed3c3dd2463f2697504881869d404106b.png"
                />
              </div>
              <div
                className="group_23 flex-col link"
                onClick={() => {
                  onClickDownload('android')
                }}
              >
                <div className="image-text_78 flex-col justify-between">
                  <img
                    className="image_89"
                    referrerPolicy="no-referrer"
                    src="/turbo-assets/img/SketchPngc03ab313f01a4fc51568a99f15e898bd5f7fbd226c9f2824d1aff295269bd406.png"
                  />
                  <span className="text-group_19">Android</span>
                </div>
                <img
                  className="label_11"
                  referrerPolicy="no-referrer"
                  src="/turbo-assets/img/SketchPng25748de131969b9a0883c2c2d44436d25fe78a673eff4f04ac65bb2c90ca32f9.png"
                />
              </div>
              <div
                className="group_24 flex-col link"
                onClick={() => {
                  onClickDownload('macos')
                }}
              >
                <div className="image-text_79 flex-col justify-between">
                  <div className="box_44 flex-col">
                    <div className="section_15 flex-col"></div>
                  </div>
                  <span className="text_77">macOS</span>
                </div>
                <img
                  className="label_12"
                  referrerPolicy="no-referrer"
                  src="/turbo-assets/img/SketchPng25748de131969b9a0883c2c2d44436d25fe78a673eff4f04ac65bb2c90ca32f9.png"
                />
              </div>
              <div
                className="group_25 flex-col link"
                onClick={() => {
                  onClickDownload('windows')
                }}
              >
                <div className="image-text_80 flex-col justify-between">
                  <img
                    className="image_90"
                    referrerPolicy="no-referrer"
                    src="/turbo-assets/img/SketchPnga7e35b403c571899d0b31c052d11b975a6b580b2189bb42938b67831d96cfdcf.png"
                  />
                  <span className="text-group_20">Windows</span>
                </div>
                <img
                  className="label_13"
                  referrerPolicy="no-referrer"
                  src="/turbo-assets/img/SketchPng25748de131969b9a0883c2c2d44436d25fe78a673eff4f04ac65bb2c90ca32f9.png"
                />
              </div>
            </div>
            <div className="text-wrapper_18">
              <span className="text_78">Version 8.0.25 </span>
              <span
                className="text_79 link"
                style={{ textDecoration: 'underline' }}
                onClick={() => {
                  onClickDownload('dashboard')
                }}
              >
                Log in to Turbo dashboard
              </span>
              <span className="text_80">→</span>
            </div>
            {/* <div className="section_17 flex-col link" data-type="dashboard">
              <div className="text-wrapper_19">
                <span className="text_81" style={{ color: '#fff' }}>
                  Get started with Turbo
                </span>
                <span className="paragraph_8">
                  <br />
                </span>
                <span className="text_82" style={{ color: '#fff' }}>
                  and maximize productivity right now!
                </span>
              </div>
            </div> */}
          </div>
          <div></div>
        </div>
        {/* <script src="/turbo-assets/index.js"></script> */}
      </div>
      <Footer />
    </div>
  )
}
